import React from "react";
import Image from "../Image";
import cls from "classnames";
export type MovieCardProps = {
  className?: string;
  url: string;
  alt?: string;
  quality?: string;
  lazyRoot?: any;
  lazyBoundary?: any;
  sizes?: any;
  placeholder?: string;
  [key: string]: any;
};

const MovieCard: React.FC<MovieCardProps> = (props) => {
  const {
    className = "",
    url = "",
    alt = "",
    quality = "50",
    lazyRoot,
    lazyBoundary = "1000px",
    sizes = "50vw",
    placeholder = "",
    priority,
    imgShadow = true,
    imgClass = "rounded-lg bg-grey-600",
    errorClass = "",
    errorTextClass = "",
  } = props;
  const c = cls(
    `w-full relative`,
    className,
    imgShadow ? "img-box-shadow" : ""
  );

  return (
    <div className={c}>
      <Image
        src={url}
        alt=""
        layout="fill"
        objectFit="cover"
        className={imgClass}
        lazyBoundary={lazyBoundary}
        quality={quality}
        lazyRoot={lazyRoot}
        sizes={sizes}
        placeholder={placeholder}
        priority={priority}
        errorClassName={errorClass}
        errorTextClassName={errorTextClass}
      />
    </div>
  );
};
export default MovieCard;
