import { useCallback, useState } from "react";
import NextImage from "./NextImage";

import cls from "classnames";

type ImageP = {
  placeholder?: string;
  src?: string;
  [key: string]: any;
  className?: string;
  errorClassName?: string;
};
const Image: React.FC<ImageP> = (props) => {
  const {
    className,
    src,
    placeholder,
    errorClassName,
    errorTextClassName,
    unoptimized = true,
    ...rest
  } = props;
  const [error, setError] = useState(false);
  const c = cls(
    { "bg-grey-600": !errorClassName },
    className,
    { error: error },
    !src || error ? errorClassName : ""
  );
  const errorTextC = cls(
    "px-4 text-base leading-5 font-semibold line-clamp-4 break-words text-center",
    !src || error ? errorTextClassName : ""
  );
  const handleError = useCallback(() => {
    setError(true);
  }, []);

  if (src && !error) {
    return (
      <NextImage
        src={src}
        alt=""
        layout="fill"
        className={c}
        unoptimized={unoptimized}
        {...rest}
        onError={handleError}
      />
    );
  }
  return (
    <div className="overflow-hidden absolute top-0 right-0 left-0 bottom-0">
      <div
        className={`flex items-center w-full h-full justify-center relative text-white-600 ${c}`}
      >
        <div className={errorTextC}>{placeholder}</div>
      </div>
    </div>
  );
};

export default Image;
