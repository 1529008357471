import c from "classnames";

export type TagProps = {
  className?: string;
  text: string;
};

const Tag: React.FC<TagProps> = (props) => {
  const cls = c(
    "text-white-500 font-semibold text-sm align-center w-max line-clamp-1",
    props.className ? props.className : "py-1.5 px-2"
  );
  return <span className={cls}>{props.text}</span>;
};

export default Tag;
